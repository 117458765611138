import { createI18n, LocaleMessages, VueMessageType } from 'vue-i18n'
import getBrowserLocale from "@/i18n/get-browser-locale"
import { supportedLocalesInclude } from "./i18n/supported-locales"
import EventBus from "./EventBus"
import { nextTick } from 'vue'

function getStartingLocale () {
	const browserLocale = getBrowserLocale({ countryCodeOnly: true })

	if (supportedLocalesInclude(browserLocale)) {
	  return browserLocale
	} else {
	  return process.env.VUE_APP_I18N_LOCALE || "en"
	}
  }

  const startingLocale = getStartingLocale()

// Detect default language of browser, and apply it on start
function detectLanguage () {
	const lng = window.navigator.language;
	const locales = require.context(
	  "./locales",
	  true,
	  /[A-Za-z0-9-_,\s]+\.json$/i
	);
	const lang = locales
	  .keys()
	  .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));
	return lang ? lang.replace("./", "").replace(".json", "") : null;
  }

  const loadedLanguages : string[] = []

  export function loadLocaleMessagesAsync (locale) {
	// EventBus.$emit("i18n-load-start")

	if (loadedLanguages.length > 0 && i18n.global.locale === locale) {
	  // EventBus.$emit("i18n-load-complete")

	  return Promise.resolve(setI18nLanguage(locale, false))
	}

	// If the language was already loaded
	if (loadedLanguages.includes(locale)) {
	  // EventBus.$emit("i18n-load-complete")
	  return Promise.resolve(setI18nLanguage(locale, false))
	}

	// If the language hasn't been loaded yet
	return import(/* webpackChunkName: "locale-[request]" */ `@/locales/${locale}.json`).then(
		messages => {
		i18n.global.setLocaleMessage(locale, messages.default)

		loadedLanguages.push(locale)

	  // EventBus.$emit("i18n-load-complete")
	  return Promise.resolve(setI18nLanguage(locale))
	})
  }

export function setI18nLanguage (locale, load = true) {
	if (load) {
		i18n.global.locale.value = locale

	  /* eslint-disable @typescript-eslint/no-use-before-define */
	  loadLocaleMessagesAsync(locale).then(() => {
		return locale;
	  });
	} else {
	  i18n.global.locale.value = locale;
	  (document.querySelector("html") as HTMLElement).setAttribute("locale", locale);
	  return locale
	}
}
const i18n = createI18n({
  legacy: false,
  locale: startingLocale,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: {},
  // messages: loadLocaleMessages(),
  globalInjection: true,
  silentFallbackWarn: true,
  useLocalStorage: true,
  useDataAttrOptions: true
})
export default i18n
