import supportedLocales from "@/config/supported-locales"
import supportedLocalesFlags from "@/config/supported-locales-flags"

export function getSupportedLocales () {
  const annotatedLocales = []

  for (const code of Object.keys(supportedLocales)) {
    annotatedLocales.push({
      code,
      name: supportedLocales[code],
	  flag: supportedLocalesFlags[code]
    })
  }
  return annotatedLocales
}

export function supportedLocalesInclude (locale) {
  return Object.keys(supportedLocales).includes(locale)
}
