import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Root from "./Root.vue"
import i18n, { loadLocaleMessagesAsync } from "@/i18n"
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
  setDocumentTitle
} from "@/i18n/document"

const { locale } = i18n.global

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: locale.value
  },
  {
    path: "/:locale",
    component: Root
  }
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior (to, from, savedPosition) {
		// always scroll to top
		return { top: 0 }
	  }
  })

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
	next()
    return
  }

  const { locale } = to.params

  loadLocaleMessagesAsync(locale).then(() => {
    setDocumentLang(locale)

    setDocumentDirectionPerLocale(locale)

    setDocumentTitle("Roman Building J.D.O.O.")
  })

  next()
})

export default router
