<template>
  <transition
    appear
    :name="transitionName"
  >
    <div
      v-if="isOpen"
      :id="elementId"
      class="cookie "
      :class="['cookie__' + type, 'cookie__' + type + '--' + position] "
    >
      <div :class="'cookie__' + type + '__wrap'">
        <div
          v-if="showPostponeButton === true"
          :class="'cookie__' + type + '__postpone-button'"
          title="Close"
          @click="postpone"
        >
          <slot name="postponeContent">
            &times;
          </slot>
        </div>

        <div :class="'cookie__' + type + '__content'">
          <h4>{{ $t("cookie.titolo") }}</h4>
          <slot name="message">
            {{ $t("cookie.messaggio") }} <a
              href="https://cookiesandyou.com/"
              target="_blank"
            ><br>{{ $t("cookie.leggi_ancora") }}</a>
          </slot>
        </div>

        <div
          class=""
          :class="'cookie__' + type + '__buttons'"
        >
          <button
            v-if="disableDecline === false"
            :class="['cookie__' + type + '__buttons__button', 'cookie__' + type + '__buttons__button--decline']"
            @click="decline"
          >
            <slot name="declineContent">
              {{ $t("cookie.declina") }}
            </slot>
          </button>
          <button
            :class="['cookie__' + type + '__buttons__button', 'cookie__' + type + '__buttons__button--accept']"
            @click="accept"
          >
            <slot name="acceptContent">
              {{ $t("cookie.accetta") }}
            </slot>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import * as tinyCookie from 'tiny-cookie'
import { bootstrap } from 'vue-gtag'

export default {
    name: 'VueCookieAcceptDecline',
    props: {
        elementId: {
            type: String,
            required: true
        },

        debug: {
            type: Boolean,
            default: false
        },

        disableDecline: {
            type: Boolean,
            default: false
        },

        // floating: bottom-left, bottom-right, top-left, top-rigt
        // bar:  bottom, top
        position: {
            type: String,
            default: 'bottom-left'
        },

        // floating, bar
        type: {
            type: String,
            default: 'floating'
        },

        // slideFromBottom, slideFromTop, fade
        transitionName: {
            type: String,
            default: 'slideFromBottom'
        },

        showPostponeButton: {
            type: Boolean,
            default: false
        },

        forceCookies: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            status: null,
            supportsLocalStorage: true,
            isOpen: false
        }
    },
    computed: {
        containerPosition () {
            return `cookie--${this.position}`
        },
        containerType () {
            return `cookie--${this.type}`
        }
    },
    mounted () {
        this.checkLocalStorageFunctionality()
        this.init()
    },
    methods: {

    	enablePlugin () {
      		bootstrap().then((gtag) => {
        	// all done!
     	 })
    	},

        init () {
            const visitedType = this.getCookieStatus()
            if (visitedType && (visitedType === 'accept' || visitedType === 'decline' || visitedType === 'postpone')) {
                this.isOpen = false
            }

            if (!visitedType) {
                this.isOpen = true
            }

            this.status = visitedType
            this.$emit('status', visitedType)
        },
        checkLocalStorageFunctionality () {
            if (this.forceCookies) {
                this.supportsLocalStorage = false
                return;
            }

            // Check for availability of localStorage
            try {
                const test = '__vue-cookie-accept-decline-check-localStorage'
                window.localStorage.setItem(test, test)
                window.localStorage.removeItem(test)
            } catch (e) {
                console.error('Local storage is not supported, falling back to cookie use')
                this.supportsLocalStorage = false
            }
        },
        setCookieStatus (type) {
            if (this.supportsLocalStorage) {
                if (type === 'accept') {
                    localStorage.setItem(`vue-cookie-accept-decline-${this.elementId}`, 'accept')
                }
                if (type === 'decline') {
                    localStorage.setItem(`vue-cookie-accept-decline-${this.elementId}`, 'decline')
                }
                if (type === 'postpone') {
                    localStorage.setItem(`vue-cookie-accept-decline-${this.elementId}`, 'postpone')
                }
            } else {
                if (type === 'accept') {
                    tinyCookie.set(`vue-cookie-accept-decline-${this.elementId}`, 'accept')
                }
                if (type === 'decline') {
                    tinyCookie.set(`vue-cookie-accept-decline-${this.elementId}`, 'decline')
                }
                if (type === 'postpone') {
                    tinyCookie.set(`vue-cookie-accept-decline-${this.elementId}`, 'postpone')
                }
            }
        },
        getCookieStatus () {
            if (this.supportsLocalStorage) {
                return localStorage.getItem(`vue-cookie-accept-decline-${this.elementId}`)
            } else {
                return tinyCookie.get(`vue-cookie-accept-decline-${this.elementId}`)
            }
        },
        accept () {
            if (!this.debug) {
                this.setCookieStatus('accept')
				this.enablePlugin()
            }

            this.status = 'accept'
            this.isOpen = false
            this.$emit('clicked-accept')
        },
        decline () {
            if (!this.debug) {
                this.setCookieStatus('decline')
            }

            this.status = 'decline'
            this.isOpen = false
            this.$emit('clicked-decline')
        },
        postpone () {
            if (!this.debug) {
                this.setCookieStatus('postpone')
            }

            this.status = 'postpone'
            this.isOpen = false
            this.$emit('clicked-postpone')
        },
        removeCookie () {
            localStorage.removeItem(`vue-cookie-accept-decline-${this.elementId}`)
            this.status = null
            this.$emit('removed-cookie')
        }
    }
}
</script>
<style lang="scss" scoped>

    $lighter-grey: #eee;
    $light-grey: #ddd;
    $grey: darken($light-grey, 9%);
    $green: #4caf50;
    $dark-green: darken($green, 8%);
    $red: #f44336;
    $dark-red: darken($red, 8%);
    $white: #fff;
    $off-white: darken($white, 2%);
    $black: #333;
	$bg_black:#343a40!important;
    $transition: all 0.2s ease;
	$transparent: rgba(255, 255, 255, 0);

    .cookie {
        // Bar
        &__bar {
            -ms-overflow-style: none;
            position: fixed;
            overflow: hidden;
            box-sizing: border-box;
            z-index: 9999;
            width: 50%;
            background: $bg_black;
            padding: 20px 20px;
            align-items: center;
            box-shadow: 0 -4px 4px rgba($grey, 0.05);
            border-top: 1px solid $bg_black;
            border-bottom: 1px solid $bg_black;
            font-size: 1rem;
            font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, Arial, sans-serif;
            line-height: 1.5;

            &--bottom {
                bottom: 0;
                left: 0;
                right: 0;
            }

            &--top {
                top: 0;
                left: 0;
                right: 0;
            }

            &__wrap {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                width: 100%;

                @media (min-width: 768px) {
                    flex-direction: row;
                }
            }

            &__postpone-button {
                margin-right: auto;
                -ms-flex: 1 1 auto;

                @media (min-width: 768px) {
                    margin-right: 10px;
                }

                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }
            }

            &__content {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 0.9rem;
                max-height: 103px;
                overflow: auto;
                width: 100%;
                -ms-flex: 1 1 auto;

                @media (min-width: 768px) {
                    margin-right: auto;
                    margin-bottom: 0;
                }
            }

            &__buttons {
                transition: $transition;
                display: flex;
                flex-direction: column;
                width: 100%;

                @media (min-width: 768px) {
                    flex-direction: row;
                    width: auto;
                }

                &__button {
                    display: inline-block;
                    font-weight: 400;
                    text-align: center;
                    white-space: nowrap;
                    vertical-align: middle;
                    user-select: none;
                    border: 1px solid white;
                    padding: 0.375rem 0.75rem;
                    line-height: 1.5;
                    border-radius: 3px;
                    font-size: 0.9rem;

                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                    }

                    &--accept {
                        -ms-flex: 1 1 auto;
                        background: $transparent;
                        background: linear-gradient(lighten($green, 5%), $green);
                        color: $white;

                        &:hover {
                            background: $white
                        }
                    }

                    &--decline {
                        -ms-flex: 1 1 auto;
                        background: $white;
                        background: linear-gradient(lighten($red, 5%), $red);
                        color: $black;
                        margin-bottom: 10px;

                        &:hover {
                            background: $white;
                        }

                        @media (min-width: 768px) {
                            margin-bottom: 0;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }

        // Floating
        &__floating {
            -ms-overflow-style: none;
            position:fixed;
            overflow: hidden;
            box-sizing: border-box;
            z-index: 9999;
            min-width: 202px;
            background: $bg_black;
            display: flex;
			flex-wrap: wrap;
            justify-content: space-between;
            flex-direction: column;
            box-shadow: 0 4px 8px rgba($black, 0.3);
            border: 1px solid $bg_black;
            font-size: 1rem;
            font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, “Fira Sans”, “Droid Sans”, “Helvetica Neue”, Arial, sans-serif;
            line-height: 1.5;
			border-radius: 2px;
			color: $white;
            @media (min-width: 768px) {
                max-width: 100%;
            }

            // For now I think the best idea is to show the panel centered
            // and on the bottom when on small screens
            bottom: 10px;
            left: 0;
            right: 0;
            margin: 0 auto;

            @media (min-width: 768px) {
                &--bottom-left {
                    bottom: 20px;
                    left: 20px;
                    right: auto;
                    margin: 0 0;
                }
            }

            @media (min-width: 768px) {
                &--bottom-right {
                    bottom: 20px;
                    right: 20px;
                    left: auto;
                    margin: 0 0;
                }
            }

            @media (min-width: 768px) {
                &--top-right {
                    top: 20px;
                    bottom: auto;
                    right: 20px;
                    left: auto;
                    margin: 0 0;
                }
            }

            @media (min-width: 768px) {
                &--top-left {
                    top: 20px;
                    bottom: auto;
                    right: auto;
                    left: 20px;
                    margin: 0 0;
                }
            }

            &__postpone-button {
                display: inline-flex;
                padding: 5px 0 0 20px;
                margin-bottom: -10px;
                margin-right: auto;

                &:hover {
                    opacity: 0.8;
                    cursor: pointer;
                }
            }

            &__content {
				text-align: left;
                font-size: 0.95rem;
                margin-bottom: 5px;
                padding: 15px 20px;
                @media (min-width: 768px) {
                    margin-bottom: 10px;
                }
            }

            &__buttons {
                transition: $transition;
                display: flex;
                flex-direction: row;
                height: auto;
                width: 100%;
				float: right;
				padding-right: 25px;
				padding-bottom: 1rem;
				justify-content:flex-end;
                &__button {
                    font-weight: bold;
                    font-size: 0.90rem;
                    min-height: 40px;
                    white-space: nowrap;
                    user-select: none;
                    border: 1px solid $white;
                    padding: 0.375rem 0.75rem;

                    &:first-child {
						margin-right: 1rem!important;
						margin-left: 1rem!important;
                    }

                    &:hover {
                        cursor: pointer;
                        text-decoration: none;
                    }

                    &--accept {
                        color: $white;
						background-color: $transparent;
                        -ms-flex: 1 1 auto;

                        &:hover {
                            background: $white;
                            color: $black;
                        }
                    }

                    &--decline {
                        color: $white;
						background-color: $transparent;
                        -ms-flex: 1 1 auto;

                        &:hover {
                            background: $white;
                            color: $black;
                        }
                    }
                }
            }
        }
    }

    // Animations
    // slideFromBottom
    .slideFromBottom-enter, .slideFromBottom-leave-to {
        transform: translate(0px, 10em);
    }

    .slideFromBottom-enter-to, .slideFromBottom-leave {
        transform: translate(0px, 0px);
    }

    .slideFromBottom-enter-active {
        transition: transform .2s ease-out;
    }

    .slideFromBottom-leave-active {
        transition: transform .2s ease-in;
    }

    // slideFromTop
    .slideFromTop-enter, .slideFromTop-leave-to {
        transform: translate(0px, -10em);
    }

    .slideFromTop-enter-to, .slideFromTop-leave {
        transform: translate(0px, 0px);
    }

    .slideFromTop-enter-active {
        transition: transform .2s ease-out;
    }

    .slideFromTop-leave-active {
        transition: transform .2s ease-in;
    }

    // fade
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>
