<template>
  <div>
    <div class="header sticky-top ">
      <ppHeader />
    </div>

    <div id="app">
      <Cookie />
      <div
        ref="immagine"
        class="immagine"
      >

      </div>
      <div
        ref="chiSiamo"
        class="chiSiamo"
      >
        <chiSiamo />
      </div>
      <div
        ref="servizi"
        class="servizi"
      >

      </div>
      <div
        ref="formContatti"
        class="formContatti"
      >
        <formContatti />
      </div>
    </div>
    <div class="footer">
      <ppFooter />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Cookie from './components/cookie.vue'
import FormContatti from './components/FormContatti.vue'
import ChiSiamo from './components/ChiSiamo.vue'


export default {
 el: '#app',
  components: {
    ppHeader: Header,
    ppFooter: Footer,
	Cookie: Cookie,
	formContatti: FormContatti,
	chiSiamo: ChiSiamo,
  },
  methods: {

  }
}

</script>

<style lang="scss">

#app {
	min-height: 74vh;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}
.text{
	font-size:1.2rem;
}
@media (max-width: 772px){
.text{
	font-size:1.1rem;
}
}

.footer{
	margin-top: 5rem;

}
</style>
